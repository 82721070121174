
import { Component, Vue } from "vue-property-decorator";
import Dashboard from "@/components/Dashboard.vue";
import Page from "@/components/core/dashboard/Page.vue";
import { RouteConfig } from "vue-router";
import store from "@/store";

@Component({
  components: {
    Page,
    Dashboard
  }
})
export default class Home extends Vue {
  sparklineData: Array<number>;

  constructor() {
    super();
    this.sparklineData = [423, 446, 675, 510, 590, 610, 423];
  }

  async copyPartnerTagLink(): Promise<void> {
    try {
      await navigator.clipboard.writeText(this.partnerTagLink);
      this.$notify({ type: "success", text: "Link copiado!" });
    } catch ($e) {
      this.$notify({
        type: "error",
        text: "Não foi possível copiar o link. Tente copiá-lo manualmente."
      });
    }
  }
  get isPartnerMasterOrPartner() {
    return ["PARTNER_MASTER", "PARTNER"].includes(this.authenticatedUser?.type);
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get selectedCompanyId() {
    return store.getters["auth/currentUserCompanyId"];
  }

  get administratorPortalUrl() {
    return (
      process.env.VUE_APP_ADMIN_PORTAL_URL ||
      "https://portal.gooroocredito.com.br"
    );
  }

  get partnerIdentifier() {
    return store.getters["auth/loggedPartnerIdentifier"];
  }

  get partnerTagLink() {
    return `${this.administratorPortalUrl}/cadastrar-empresa?partner=${this.partnerIdentifier}`;
  }

  get menuItens() {
    const menuItems: RouteConfig[] = [];

    this.$router.options.routes?.forEach((parent) => {
      if (parent.children?.length) {
        parent.children.forEach((children) => {
          if (children.meta!.showInMenu) {
            menuItems.push(children);
          }
        });
      } else {
        if (parent.meta?.showInMenu) {
          menuItems.push(parent);
        }
      }
    });
    return menuItems;
  }
}
