var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"title":""}},[(
      _vm.hasPermissions(['LISTAR_RELATORIOS_CONTRATOS_PGTO_EFETUADO']) ||
      _vm.hasPermissions(['LISTAR_RELATORIO_CARTEIRA_CREDITO']) ||
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESAS_ATIVAS']) ||
      _vm.hasPermissions(['LISTAR_RELATORIO_TICKET_MEDIO_CONTRATOS']) ||
      _vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_EM_CONTRATACAO']) ||
      _vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_PARCELAS_ATRASO'])
    )?_c('div',[_c('Dashboard')],1):_c('v-card',{staticClass:"primary--text pa-10 mb-10",attrs:{"color":"accent","elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 d-flex align-end justify-center",attrs:{"cols":"12","lg":"3"}},[_c('img',{staticClass:"mb-6 mb-lg-n7",attrs:{"src":require("@/assets/quem-pode-contratar-credito.png"),"width":"100%"}})]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"9"}},[_c('div',[_c('h2',{staticClass:"font-weight-bold text-h4"},[_vm._v("Gooroo Crédito")]),_c('h5',{staticClass:"font-weight-thin text-h6 mb-6"},[_vm._v("Painel administrativo")]),_vm._l((_vm.menuItens),function(item){return [(
                item.path !== '/' &&
                _vm.hasPermissions(item.meta.requiredPermissions) &&
                (_vm.authenticatedUser.type !== 'EMPLOYEE' || _vm.selectedCompanyId)
              )?_c('v-btn',{key:item.name,staticClass:"mr-1 mb-2 px-6",attrs:{"link":"","to":{ name: item.name },"exact":"","large":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.meta.icon))]),_vm._v(" "+_vm._s(item.meta.title)+" ")],1):_vm._e()]}),(_vm.isPartnerMasterOrPartner && _vm.partnerIdentifier)?_c('v-col',{staticClass:"font-weight-light text-body-2 mt-6"},[_c('v-row',{attrs:{"align":"center"}},[_c('span',[_vm._v("Este é o seu link: "),_c('a',{attrs:{"href":_vm.partnerTagLink}},[_vm._v(_vm._s(_vm.partnerTagLink))])]),_c('v-btn',{staticClass:"ml-4",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":_vm.copyPartnerTagLink}},[_c('v-icon',{attrs:{"left":"","size":"medium"}},[_vm._v("mdi-content-copy")]),_vm._v(" Copiar")],1)],1),_c('v-row',[_c('span',[_vm._v("É através dele que seus clientes devem se cadastrar.")])])],1):_vm._e()],2)])],1)],1),(
      _vm.authenticatedUser &&
      _vm.authenticatedUser.type === 'EMPLOYEE' &&
      !_vm.selectedCompanyId
    )?_c('div',[_c('v-alert',{attrs:{"dark":"","color":"red"}},[_vm._v(" Selecione uma empresa no menu superior para continuar ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }