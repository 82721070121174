
import formatCurrency from "@/utils/formatCurrency";
import LoanService from "@/services/loan-service";
import { Vue, Component } from "vue-property-decorator";
import {
  CreditPortfolioReport,
  ActiveCompaniesReport,
  AverageTicketOfLoansReport,
  TotalInSimulationLoansReport,
  TotalDueInstallmentsReport,
  PaidLoansMonthData
} from "@/types/dashboard-reports";
import DashboardReportPercentage from "@/components/DashboardReportPercentage.vue";
import { MonthShortAndLongForm } from "@/utils/months";

interface SelectOptions {
  value: string;
  text: string;
}

@Component({
  components: {
    DashboardReportPercentage
  }
})
export default class Modal extends Vue {
  formatCurrency = (value: number) => {
    if (value >= 10000) return formatCurrency(value, "compact");
    return formatCurrency(value);
  };
  MonthShortAndLongForm = MonthShortAndLongForm;
  period: string = "DAY";
  periodOptions: Array<SelectOptions> = [
    { value: "DAY", text: "Hoje" },
    { value: "MONTH", text: "Mês" },
    { value: "SEMESTER", text: "Últimos 6 meses" },
    { value: "YEAR", text: "Ano" }
  ];

  paidLoansByMonthTab: number = 0;
  creditWalletTab: number = 0;
  loansService: LoanService;
  creditPortfolioReport: CreditPortfolioReport | null = null;
  activeCompaniesReport: ActiveCompaniesReport | null = null;
  averageTicketOfLoansReport: AverageTicketOfLoansReport | null = null;
  totalInSimulationLoansReport: TotalInSimulationLoansReport | null = null;
  totalDueInstallmentsReport: TotalDueInstallmentsReport | null = null;
  paidLoansReport: PaidLoansMonthData[] | null = null;

  constructor() {
    super();
    this.loansService = LoanService.getInstance();
  }

  async mounted(): Promise<void> {
    await this.loadReports();
  }

  async loadReports(): Promise<void> {
    this.creditPortfolioReport = null;
    this.activeCompaniesReport = null;
    this.averageTicketOfLoansReport = null;
    this.totalInSimulationLoansReport = null;
    this.totalDueInstallmentsReport = null;
    this.paidLoansReport = null;

    const [creditPortfolioReportError, creditPortfolioReport] =
      await this.loansService.creditPortfolioReport(this.period);
    if (!creditPortfolioReportError && creditPortfolioReport) {
      this.creditPortfolioReport = creditPortfolioReport;
    }

    const [activeCompaniesReportError, activeCompaniesReport] =
      await this.loansService.activeCompaniesReport(this.period);
    if (!activeCompaniesReportError && activeCompaniesReport) {
      this.activeCompaniesReport = activeCompaniesReport;
    }

    const [averageTicketOfLoansReportError, averageTicketOfLoansReport] =
      await this.loansService.averageTicketOfLoansReport(this.period);
    if (!averageTicketOfLoansReportError && averageTicketOfLoansReport) {
      this.averageTicketOfLoansReport = averageTicketOfLoansReport;
    }

    const [totalInSimulationLoansReportError, totalInSimulationLoansReport] =
      await this.loansService.totalInSimulationLoansReport(this.period);
    if (!totalInSimulationLoansReportError && totalInSimulationLoansReport) {
      this.totalInSimulationLoansReport = totalInSimulationLoansReport;
    }

    const [totalDueInstallmentsReportError, totalDueInstallmentsReport] =
      await this.loansService.totalDueInstallmentsReport(this.period);
    if (!totalDueInstallmentsReportError && totalDueInstallmentsReport) {
      this.totalDueInstallmentsReport = totalDueInstallmentsReport;
    }

    const [paidLoansReportError, paidLoansReport] =
      await this.loansService.paidLoansReport();
    if (!paidLoansReportError && paidLoansReport) {
      this.paidLoansReport = paidLoansReport;

      // calculate percentage by month
      const biggestMonthValue = Math.max(
        ...paidLoansReport.map(
          (data: PaidLoansMonthData) => data.count.value || 0
        )
      );

      paidLoansReport.forEach((data: PaidLoansMonthData) => {
        data.calculatedPercentage =
          (data.count.value * 100) / biggestMonthValue;
      });

      this.paidLoansByMonthTab = paidLoansReport.length - 1;
    }
  }

  get activeMonth(): PaidLoansMonthData | null {
    if (this.paidLoansReport) {
      return this.paidLoansReport[this.paidLoansByMonthTab];
    }

    return null;
  }
}
