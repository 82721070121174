var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-autocomplete',{attrs:{"hide-details":"auto","items":_vm.periodOptions},on:{"change":_vm.loadReports},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)],1),_c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-row',[(
              _vm.creditPortfolioReport &&
              _vm.hasPermissions(['LISTAR_RELATORIO_CARTEIRA_CREDITO'])
            )?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"elevation-4 pa-6 text-center dashboard-card"},[_c('div',{staticClass:"mb-6"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("CARTEIRA DE CRÉDITO")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.creditWalletTab === 0 ? "Valor recebido" : "A receber")+" ")])]),_c('div',[(_vm.creditWalletTab === 0)?_c('div',[_c('h1',{staticClass:"font-weight-black mb-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.creditPortfolioReport.totalReceived.currentPeriod ))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.creditPortfolioReport.totalReceived
                        .currentVsPreviousDiffPercentage,"period":_vm.period}})],1):_vm._e(),(_vm.creditWalletTab === 1)?_c('div',[_c('h1',{staticClass:"font-weight-black mb-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.creditPortfolioReport.totalToBeReceived.currentPeriod ))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.creditPortfolioReport.totalToBeReceived
                        .currentVsPreviousDiffPercentage,"period":_vm.period}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"card-tab",class:{ active: _vm.creditWalletTab === 0 },on:{"click":function($event){_vm.creditWalletTab = 0}}}),_c('div',{staticClass:"card-tab",class:{ active: _vm.creditWalletTab === 1 },on:{"click":function($event){_vm.creditWalletTab = 1}}})])])])]):_vm._e(),(
              _vm.activeCompaniesReport &&
              _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESAS_ATIVAS'])
            )?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"elevation-4 pa-6 text-center dashboard-card"},[_c('div',{staticClass:"mb-10"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("EMPRESAS ATIVAS")])]),_c('div',[_c('h1',{staticClass:"font-weight-black mb-1 text-h3"},[_vm._v(" "+_vm._s(_vm.activeCompaniesReport.activeCompanies.currentPeriod)+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.activeCompaniesReport.activeCompanies
                      .currentVsPreviousDiffPercentage,"period":_vm.period}})],1)])]):_vm._e()],1),(
            _vm.averageTicketOfLoansReport &&
            _vm.hasPermissions(['LISTAR_RELATORIO_TICKET_MEDIO_CONTRATOS'])
          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"elevation-4 pa-6 text-center dashboard-card"},[_c('div',{staticClass:"mb-6"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("TICKET MÉDIO DE CONTRATOS")])]),_c('div',[_c('h1',{staticClass:"font-weight-black mb-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.averageTicketOfLoansReport.averageTicket.currentPeriod ))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.averageTicketOfLoansReport.averageTicket
                      .currentVsPreviousDiffPercentage,"period":_vm.period}})],1)])])],1):_vm._e()],1),(
          (_vm.totalInSimulationLoansReport || _vm.totalDueInstallmentsReport) &&
          (_vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_EM_CONTRATACAO']) ||
            _vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_PARCELAS_ATRASO']))
        )?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"elevation-4 pa-6 text-center dashboard-card"},[(
              _vm.totalInSimulationLoansReport &&
              _vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_EM_CONTRATACAO'])
            )?_c('div',[_c('div',{staticClass:"mb-10"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("TOTAL EM CONTRATAÇÃO")])]),_c('div',{staticClass:"mb-12"},[_c('h1',{staticClass:"font-weight-black mb-1 success--text"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.totalInSimulationLoansReport.totalInSimulation .currentPeriod ))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.totalInSimulationLoansReport.totalInSimulation
                    .currentVsPreviousDiffPercentage,"period":_vm.period}})],1)]):_vm._e(),(
              _vm.totalDueInstallmentsReport &&
              _vm.hasPermissions(['LISTAR_RELATORIO_TOTAL_PARCELAS_ATRASO'])
            )?_c('div',[_c('div',{staticClass:"mb-6"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("TOTAL EM ATRASO")])]),_c('div',[_c('h1',{staticClass:"font-weight-black mb-1 error--text"},[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.totalDueInstallmentsReport.totalDueInstallments .currentPeriod ))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.totalDueInstallmentsReport.totalDueInstallments
                    .currentVsPreviousDiffPercentage,"period":_vm.period}})],1)]):_vm._e()])]):_vm._e()],1),(
        _vm.paidLoansReport &&
        _vm.activeMonth &&
        _vm.hasPermissions(['LISTAR_RELATORIOS_CONTRATOS_PGTO_EFETUADO'])
      )?_c('div',{staticClass:"elevation-4 pa-6 text-left dashboard-card"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('h4',{staticClass:"font-weight-bold mb-3"},[_vm._v("CONTRATOS PAGAMENTO EFETUADO")]),_c('h1',{staticClass:"font-weight-black mb-1 text-h3"},[_vm._v(" "+_vm._s(_vm.activeMonth.count.value)+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.activeMonth.count.diffFromPreviousMonthInPercentage,"period":"MONTH","align":'left'}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.paidLoansReport),function(paidLoansByMonth,index){return _c('v-col',{key:'month-' + index,staticClass:"d-flex align-end",attrs:{"cols":"1"}},[_c('div',{staticClass:"w-100 text-center px-1",staticStyle:{"width":"100%","cursor":"pointer"},on:{"click":function($event){_vm.paidLoansByMonthTab = index}}},[_c('div',{staticClass:"chart-bar",class:{
                    active: index === _vm.paidLoansByMonthTab
                  },style:('height: ' +
                    (paidLoansByMonth.calculatedPercentage &&
                    paidLoansByMonth.calculatedPercentage > 0
                      ? paidLoansByMonth.calculatedPercentage
                      : 0) *
                      1.4 +
                    'px;')}),_c('div',{staticClass:"caption"},[_c('small',[_vm._v(_vm._s(_vm.MonthShortAndLongForm[paidLoansByMonth.month].short))])])])])}),1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("VALOR DE PRODUÇÃO")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.MonthShortAndLongForm[_vm.activeMonth.month].long)+"/"+_vm._s(_vm.activeMonth.year)+" ")]),_c('h1',{staticClass:"font-weight-black mb-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.activeMonth.requestedAmount.value))+" ")]),_c('DashboardReportPercentage',{attrs:{"percentage":_vm.activeMonth.requestedAmount.diffFromPreviousMonthInPercentage,"period":"MONTH","align":'left'}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"mb-16"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"primary--text"},[_c('h2',{staticClass:"font-weight-bold text-h4"},[_vm._v("Visão geral")]),_c('h5',{staticClass:"font-weight-thin text-h6 mb-6"},[_vm._v(" Dados atualizados em tempo real ")])])
}]

export { render, staticRenderFns }